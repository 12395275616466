import BannerScreen from "../screens/banner";
import BannerDetailScreen from "../screens/banner/detail";
import DiscoverScreen from "../screens/discover";
import DiscoverDetailScreen from "../screens/discover/detail";
import DailyScreen from "../screens/daily";
import CourseScreen from "../screens/course";
import CourseDetailScreen from "../screens/course/detail";
import NewsScreen from "../screens/news";
import NewsDetailScreen from "../screens/news/detail";
import DailyDetailScreen from "../screens/daily/detail";
import CommunityDetailScreen from "../screens/community/detail";
import DailyQaScreen from "../screens/daily/qa_index";
import UsersScreen from "../screens/users";
import UserRecommendScreen from "../screens/users/recommend_user";
import TopicScreen from "../screens/community/bounty_topic";
import DashboardScreen from "../screens/dashboard";
import SystemSettingScreen from "../screens/system";
import ReportScreen from "../screens/community/report";
import CommentsScreen from "../screens/community/comment";
import PointProductsScreen from "../screens/points/products";
import PointBalanceScreen from "../screens/points/balances";
import UserLabelScreen from "../screens/users/labels";
import HomeArticleScreen from "../screens/home/hot_article";
import HomeTopicScreen from "../screens/home/hot_topic";
import PointRecordsScreen from "../screens/points/history";
import AllTagsScreen from "../screens/community/topic_tags";
import TagDetailScreen from "../screens/community/tagdetail";
import ContentTagsScreen from "../screens/community/content_tags";
import UserPostsScreen from "../screens/community/user_posts";
import UserArticlesScreen from "../screens/community/user_articles";
import AdminPostsScreen from "../screens/community/admin_posts";
import AdminArticlesScreen from "../screens/community/admin_articles";
import WalletScreen from "../screens/crypto/wallet";
import TransactionsScreen from "../screens/crypto/transactions";
import HotBountyScreen from "../screens/discover/hot_bounty";
import AdminsScreen from "../screens/system/admins";
import RolesScreen from "../screens/system/roles";
import RoleDetailScreen from "../screens/system/role_detail";
import DiscoverHotPostsScreen from "../screens/discover/hot_post";
import DiscoverHotVideosScreen from "../screens/discover/hot_video";
import VirtualUserScreen from "../screens/users/virtual_user";
import PointDataScreen from "../screens/points/datas";
import PointSettingScreen from "../screens/points/setting";
import CrawledPostsScreen from "../screens/community/crawled_posts";
import CrawledDetailScreen from "../screens/community/crawled_detail";
import PendingPostsScreen from "../screens/community/pending_posts";
import PendingArticlesScreen from "../screens/community/pending_articles";
import PendingCommentsScreen from "../screens/community/pending_comments";
import ProfilingQuizScreen from "../screens/users/profiling_quiz";
import PMProductsScreen from "../screens/privatemarket/products";
import PMProductDetailScreen from "../screens/privatemarket/productdetail";
import PMTopPicksScreen from "../screens/privatemarket/top_picks";
import PMRecommendedForYouScreen from "../screens/privatemarket/recommended_for_you";
import PMInsightsCoverScreen from "../screens/privatemarket/insights_cover";
import PMAssetClassScreen from "../screens/privatemarket/asset_class";
import LearningArticlesScreen from "../screens/course/articles";
import LearningArticleDetailScreen from "../screens/course/article_detail";
import CourseCategoriesScreen from "../screens/course/categories";
import LearningCenterScreen from "../screens/course/learn_center";
import LearningCenterConfigScreen from "../screens/course/center_config";
import WhitelistUserScreen from "../screens/users/whitelist_user";
import LearningCoursesScreen from "../screens/course/courses";
import LearningCourseDetailScreen from "../screens/course/course_detail";

export const MainLayoutUrl = {
    dashboard: {
        index: {
            path: "/dashboard",
            selectedKey: "dashboard",
            element: <DashboardScreen />,
        },
    },
    banner: {
        index: {
            path: "/banners",
            selectedKey: "banner",
            element: <BannerScreen />
        },
        item: {
            path: "/banners/:id",
            key: 'bannerItem',
            selectedKey: "banner",
            element: <BannerDetailScreen />
        },
    },
    homepage: {
        hotarticle: {
            index: {
                path: "/hotarticle",
                selectedKey: "hotarticle",
                needCache: true,
                element: <HomeArticleScreen />
            },
        },
        hottag: {
            index: {
                path: "/hottag",
                selectedKey: "hottag",
                needCache: true,
                element: <HomeTopicScreen />
            },
        },
    },
    discover: {
        videos: {
            index: {
                path: "/videos",
                selectedKey: "videos",
                needCache: true,
                element: <DiscoverScreen />
            },
            item: {
                path: "/videos/:id",
                key: "videoItem",
                selectedKey: "videos",
                element: <DiscoverDetailScreen />
            },
        },
        hotbounty: {
            index: {
                path: "/hotbounty",
                selectedKey: "hotbounty",
                element: <HotBountyScreen />
            },
        },
        hotpost: {
            index: {
                path: "/hotpost",
                selectedKey: "hotpost",
                element: <DiscoverHotPostsScreen />
            },
        },
        hotvideo: {
            index: {
                path: "/hotvideo",
                selectedKey: "hotvideo",
                element: <DiscoverHotVideosScreen />
            },
        }
    },
    daily: {
        knowledge: {
            index: {
                path: "/knowledge",
                selectedKey: "knowledge",
                element: <DailyScreen />
            },
            item: {
                path: "/knowledge/:id",
                key: "dailyItem",
                selectedKey: "knowledge",
                element: <DailyDetailScreen />
            },
        },
        question: {
            index: {
                path: "/question",
                selectedKey: "question",
                element: <DailyQaScreen />
            },
        },
    },
    course: {
        index: {
            path: "/courses",
            selectedKey: "course",
            element: <CourseScreen />
        },
        item: {
            path: "/courses/:id",
            key: "courseItem",
            selectedKey: "course",
            element: <CourseDetailScreen />
        },
    },
    learning: {
        learningarticles: {
            index: {
                path: "/learningarticles",
                selectedKey: "learningarticles",
                element: <LearningArticlesScreen />
            },
            item: {
                path: "/learningarticles/:id",
                key: "articleItem",
                selectedKey: "learningarticles",
                element: <LearningArticleDetailScreen />
            },
        },
        learningcourses: {
            index: {
                path: "/learningcourses",
                selectedKey: "learningcourses",
                element: <LearningCoursesScreen />
            },
            item: {
                path: "/learningcourses/:id",
                key: "courseItem",
                selectedKey: "learningcourses",
                element: <LearningCourseDetailScreen />
            },
        },
        coursecatalogs: {
            index: {
                path: "/coursecatalogs",
                selectedKey: "coursecatalogs",
                element: <CourseCategoriesScreen />
            },
        },
        learningcenter: {
            index: {
                path: "/learningcenter",
                selectedKey: "learningcenter",
                element: <LearningCenterScreen />
            },
            item: {
                path: "/learningcenter/:id",
                key: "learningCenterItem",
                selectedKey: "learningcenter",
                element: <LearningCenterConfigScreen />
            },
        }
    },
    news: {
        index: {
            path: "/news",
            selectedKey: "news",
            element: <NewsScreen />
        },
        item: {
            path: "/news/:id",
            key: "newsItem",
            selectedKey: "news",
            element: <NewsDetailScreen />
        },
    },
    community: {
        bountytopic: {
            index: {
                path: "/bountytopic",
                selectedKey: "bountytopic",
                element: <TopicScreen />
            },
        },
        userpost: {
            index: {
                path: "/userpost",
                selectedKey: "userpost",
                needCache: true,
                element: <UserPostsScreen />
            }
        },
        userarticle: {
            index: {
                path: "/userarticle",
                selectedKey: "userarticle",
                needCache: true,
                element: <UserArticlesScreen />
            }
        },
        adminpost: {
            index: {
                path: "/adminpost",
                selectedKey: "adminpost",
                needCache: true,
                element: <AdminPostsScreen />
            },
            item: {
                path: "/adminpost/:id",
                key: "postItem",
                selectedKey: "adminpost",
                element: <CommunityDetailScreen />
            },
        },
        adminarticle: {
            index: {
                path: "/adminarticle",
                selectedKey: "adminarticle",
                needCache: true,
                element: <AdminArticlesScreen />
            }
        },
        report: {
            index: {
                path: "/report",
                selectedKey: "report",
                element: <ReportScreen />
            },
        },
        comment: {
            index: {
                path: "/comment",
                selectedKey: "comment",
                needCache: true,
                element: <CommentsScreen />
            },
        },
        topictags: {
            index: {
                path: "/topictags",
                selectedKey: "topictags",
                needCache: true,
                element: <AllTagsScreen />
            },
            item: {
                path: "/topictags/:id",
                key: 'tagItem',
                selectedKey: "topictags",
                element: <TagDetailScreen />
            },
        },
        contenttags: {
            index: {
                path: "/contenttags",
                selectedKey: "contenttags",
                needCache: true,
                element: <ContentTagsScreen />
            },
        },
        crawled: {
            index: {
                path: "/crawled",
                selectedKey: "crawled",
                needCache: true,
                element: <CrawledPostsScreen />
            },
            item: {
                path: "/crawled/:id",
                key: 'crawledItem',
                selectedKey: "crawled",
                element: <CrawledDetailScreen />
            },
        },
        pendingposts: {
            index: {
                path: "/pendingposts",
                selectedKey: "pendingposts",
                element: <PendingPostsScreen />
            },
        },
        pendingarticles: {
            index: {
                path: "/pendingarticles",
                selectedKey: "pendingarticles",
                element: <PendingArticlesScreen />
            },
        },
        pendingcomments: {
            index: {
                path: "/pendingcomments",
                selectedKey: "pendingcomments",
                element: <PendingCommentsScreen />
            },
        }
    },
    privatemarket: {
        privateproducts: {
            index: {
                path: "/privateproducts",
                selectedKey: "privateproducts",
                element: <PMProductsScreen />
            },
            item: {
                path: "/privateproducts/:id",
                key: 'privateproductsItem',
                selectedKey: "privateproducts",
                element: <PMProductDetailScreen />
            }
        },
        toppicks: {
            index: {
                path: "/toppicks",
                selectedKey: "toppicks",
                element: <PMTopPicksScreen />
            },
        },
        recommendedforyou: {
            index: {
                path: "/recommendedforyou",
                selectedKey: "recommendedforyou",
                element: <PMRecommendedForYouScreen />
            },
        },
        insightscover: {
            index: {
                path: "/insightscover",
                selectedKey: "insightscover",
                element: <PMInsightsCoverScreen />
            },
        },
        assetclass: {
            index: {
                path: "/assetclass",
                selectedKey: "assetclass",
                element: <PMAssetClassScreen />
            },
        }
    },
    users: {
        alluser: {
            index: {
                path: "/alluser",
                selectedKey: "alluser",
                needCache: true,
                element: <UsersScreen />
            },
        },
        recommenduser: {
            index: {
                path: "/recommenduser",
                selectedKey: "recommenduser",
                element: <UserRecommendScreen />
            },
        },
        whitelistuser: {
            index: {
                path: "/whitelistuser",
                selectedKey: "whitelistuser",
                element: <WhitelistUserScreen />
            },
        },
        ulabels: {
            index: {
                path: "/ulabels",
                selectedKey: "ulabels",
                element: <UserLabelScreen />
            },
        },
        virtualuser: {
            index: {
                path: "/virtualuser",
                selectedKey: "virtualuser",
                element: <VirtualUserScreen />
            }
        },
        profilingquiz: {
            index: {
                path: "/profilingquiz",
                selectedKey: "profilingquiz",
                element: <ProfilingQuizScreen />
            }
        }
    },
    points: {
        pointdata: {
            index: {
                path: "/pointdata",
                selectedKey: "pointdata",
                element: <PointDataScreen />
            },
        },
        products: {
            index: {
                path: "/products",
                selectedKey: "products",
                needCache: true,
                element: <PointProductsScreen />
            },
        },
        balance: {
            index: {
                path: "/balance",
                selectedKey: "balance",
                element: <PointBalanceScreen />
            },
        },
        pointrecords: {
            index: {
                path: "/pointrecords",
                selectedKey: "pointrecords",
                needCache: true,
                element: <PointRecordsScreen />
            },
        },
        pointsetting: {
            index: {
                path: "/pointsetting",
                selectedKey: "pointsetting",
                element: <PointSettingScreen />
            },
        },
    },
    crypto: {
        wallet: {
            index: {
                path: "/wallet",
                selectedKey: "wallet",
                element: <WalletScreen />
            },
        },
        transactions: {
            index: {
                path: "/transactions",
                selectedKey: "transactions",
                element: <TransactionsScreen />
            },
        }
    },
    systemconfig: {
        config: {
            index: {
                path: "/configsetting",
                selectedKey: "configsetting",
                element: <SystemSettingScreen />
            },
        },
        admins: {
            index: {
                path: "/admins",
                selectedKey: "admins",
                element: <AdminsScreen />
            },
        },
        roles: {
            index: {
                path: "/roles",
                selectedKey: "roles",
                element: <RolesScreen />
            },
            item: {
                path: "/roles/:id",
                key: "roleItem",
                selectedKey: "roles",
                element: <RoleDetailScreen />
            }
        }
    }
};